<template>
  <div class="jxDataSocre">
    <van-nav-bar
      :title="$t('jxDataSocreDetail.txt1')"
      :fixed="true"
      left-text
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="fixedH" style="height: 1.22667rem"></div>
    <div class="container">
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="(value, key) in resData"
          :key="key"
          :title="value.name"
          :name="'active' + (key + 1)"
        >
          <van-collapse
            v-model="value.activeNames2"
            class="childCollapse"
            @change="changeTwo"
          >
            <van-collapse-item
              :name="key + 'name' + (index + 1)"
              v-for="(item, index) in value.data"
              :key="index"
            >
              <template #title>
                <div class="second_title">
                  <div class="second_name">
                    {{ index + 1 + '.' + item.itemname }}
                    <span
                      :class="{
                        dl: item.idtype == '定量',
                        dx: item.idtype == '定性',
                        ic: true
                      }"
                      >{{ item.idtype }}</span
                    >
                  </div>
                  <div class="second_score">
                    {{ item.totalscore }}{{ $t('jxDataSocreDetail.txt5') }}
                  </div>
                </div>
              </template>
              <div class="tips_container">
                <div class="khf_shabox">
                  <p>{{ item.itemdesc }}</p>
                  <div class="score_shabox">
                    <div v-if="$t('jxDataSocreDetail.txt2')" class="thrid_per">
                      {{ $t('jxDataSocreDetail.txt2') }}{{ item.itemper }}
                    </div>
                    <div v-if="$t('jxDataSocreDetail.txt3')" class="thrid_tg">
                      <strong style="color:#000;">{{
                        $t('jxDataSocreDetail.txt3')
                      }}</strong>
                      {{ item.pfbz }}
                    </div>
                    <!-- {{ $t('jxDataSocreDetail.txt5') }} 去掉分 -->
                  </div>
                </div>

                <!-- 评分规则 -->
                <van-collapse
                  class="thrid_coll"
                  v-if="$t('jxDataSocreDetail.txt4')"
                  v-model="item.activeNames3"
                  @change="changeTwo(item, 'gz')"
                >
                  <van-collapse-item
                    :name="key + 'gz' + index"
                    :title="$t('jxDataSocreDetail.txt4')"
                  >
                    <template #right-icon>
                      <div class="right-icon">
                        {{
                          `${
                            item.showgz
                              ? $t('jxDataSocreDetail.txt6')
                              : $t('jxDataSocreDetail.txt7')
                          }`
                        }}
                        <van-icon
                          :name="item.showgz ? 'arrow-up' : 'arrow-down'"
                        />
                      </div>
                    </template>
                    <div class="thrid_desc">
                      <div class="desp">{{ item.pfdesp }}</div>
                    </div>
                  </van-collapse-item>
                </van-collapse>

                <div
                  class="select_container"
                  v-if="
                    $t('jxDataSocreDetail.txt20') ||
                      $t('jxDataSocreDetail.txt21') ||
                      $t('jxDataSocreDetail.txt22')
                  "
                >
                  <div
                    class="select_input"
                    v-if="
                      item.idtype != '定性' && $t('jxDataSocreDetail.txt20')
                    "
                  >
                    <h3>{{ $t('jxDataSocreDetail.txt20') }}</h3>
                    <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                    <div>
                      <input
                        :disabled="true"
                        type="text"
                        v-model="item.tgvalue"
                      />
                    </div>
                  </div>
                  <div
                    class="select_input"
                    v-if="
                      item.idtype != '定性' && $t('jxDataSocreDetail.txt21')
                    "
                  >
                    <h3>{{ $t('jxDataSocreDetail.txt21') }}</h3>
                    <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                    <div>
                      <input
                        :disabled="true"
                        type="text"
                        v-model="item.alarmvalue"
                      />
                    </div>
                  </div>
                  <div
                    class="select_input"
                    v-if="
                      item.idtype != '定性' && $t('jxDataSocreDetail.txt22')
                    "
                  >
                    <h3>{{ $t('jxDataSocreDetail.txt22') }}</h3>
                    <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                    <div>
                      <input
                        :disabled="true"
                        type="text"
                        v-model="item.realscore"
                      />
                    </div>
                  </div>
                </div>

                
                <div
                  class="select_container"
                  v-if="
                    $t('jxDataSocreDetail.txt13') ||
                      $t('jxDataSocreDetail.txt14') ||
                      $t('jxDataSocreDetail.txt15')
                  "
                >
                  <div
                    v-if="
                      show_is_zp == 'true' &&
                        (item.idtype != '定量' ||
                          (item.idtype == '定量' && show_can_pj_dl)) &&
                        $t('jxDataSocreDetail.txt13')
                    "
                    class="select_input"
                  >
                    <h3>{{ $t('jxDataSocreDetail.txt13') }}</h3>
                    <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                    <div
                      style="display: flex;align-items: center;justify-content: center;"
                    >
                      <input
                        :disabled="!item.is_zp"
                        type="text"
                        v-model="item.itemscore"
                        :style="{ width: item.zpunit ? '60%' : '' }"
                      />
                      <span>{{ item.zpunit }}</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      show_is_zjp == 'true' &&
                        (item.idtype != '定量' ||
                          (item.idtype == '定量' && show_can_pj_dl)) &&
                        $t('jxDataSocreDetail.txt14')
                    "
                    class="select_input"
                  >
                    <h3>{{ $t('jxDataSocreDetail.txt14') }}</h3>
                    <!-- :disabled="item.idtype == '定量' || checkflag == 1 || checkflag == 3" -->
                    <div>
                      <input
                        :disabled="!item.is_zjp"
                        type="text"
                        v-model="item.zjsj_score"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      show_is_jjp == 'true' &&
                        (item.idtype != '定量' ||
                          (item.idtype == '定量' && show_can_pj_dl)) &&
                        $t('jxDataSocreDetail.txt15')
                    "
                    class="select_input"
                  >
                    <h3>{{ $t('jxDataSocreDetail.txt15') }}</h3>
                    <!--  :disabled="item.idtype == '定量' || checkflag == 1 || checkflag == 2" -->
                    <div>
                      <input
                        :disabled="!item.is_jjp"
                        type="text"
                        v-model="item.jjsj_score"
                      />
                    </div>
                  </div>
                </div>

                <!-- 统计项目 -->
                <van-collapse
                  class="thrid_coll"
                  v-if="$t('jxDataSocreDetail.txt8') && item.idtype != '定性'"
                  v-model="activeNames4"
                  @change="item.showtj = !item.showtj"
                >
                  <van-collapse-item
                    :name="key + 'tj' + index"
                    :title="$t('jxDataSocreDetail.txt8')"
                  >
                    <template #right-icon>
                      <div class="right-icon">
                        {{
                          `${
                            item.showtj
                              ? $t('jxDataSocreDetail.txt6')
                              : $t('jxDataSocreDetail.txt7')
                          }`
                        }}
                        <van-icon
                          :name="item.showtj ? 'arrow-up' : 'arrow-down'"
                        />
                      </div>
                    </template>
                    <div class="thrid_desc">
                      <div class="desp">
                        <div
                          class="Sta_poj"
                          v-for="(el, eid) in item.child"
                          :key="eid"
                        >
                          <div>{{ el.itemname }}</div>
                          <div>{{ el.itemvalue }}</div>
                        </div>
                      </div>
                      <!-- 统计项目附件 -->
                      <!-- v-if="(show_is_zp == 'true' ||show_is_zp == true) && (up_detail_atfile == true || up_detail_atfile == 'true')" -->
                      <div style="padding: 10px; border-top: 1px solid;">
                        <div style="text-align: center;margin-bottom: 10px;">
                          附件（最多15张）
                        </div>
                        <!-- v-if="item.is_zp == 1 " -->
                        <div
                          style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
                        >
                          <div
                            v-for="(it, index) in item.tjAttFileList"
                            :key="index"
                            style="margin:0 8px 8px 0"
                          >
                            <van-badge>
                              <van-image
                                v-if="it.fileext.indexOf('pdf') == -1"
                                width="80"
                                height="80"
                                :src="it.url"
                                @click="imagePreview(item.tjAttFileList)"
                              />
                              <van-image
                                v-else
                                width="80"
                                height="80"
                                :src="require('@assets/img/xmind.png')"
                                @click="showPdf(it)"
                              />
                              <template #content>
                                <van-icon
                                  name="cross"
                                  class="badge-icon"
                                  @click="deleteAttFile(it, 'norm', 'tj')"
                                />
                              </template>
                            </van-badge>
                          </div>
                        </div>
                        <!-- <div v-else
                             style="display:flex;flex-wrap: wrap;justify-content: flex-start;">
                          <div v-for="(it,index) in item.tjAttFileList"
                               :key="index"
                               style="margin:0 8px 8px 0">
                            <van-image v-if="it.fileext.indexOf('pdf')==-1"
                                       width="80"
                                       height="80"
                                       :src="it.url"
                                       @click="imagePreview(item.tjAttFileList)" />
                            <van-image v-else
                                       width="80"
                                       height="80"
                                       :src="require('@assets/img/xmind.png')"
                                       @click="showPdf(it)" />
                          </div>
                        </div>-->
                        <!-- v-if="item.is_zp == 1 " -->
                        <van-uploader
                          accept="image/*, .pdf"
                          preview-size="80px"
                          v-model="item.tjFileList"
                          :max-count="15 - item.tjAttFileList.length"
                          :after-read="afterRead"
                        />
                      </div>
                    </div>
                  </van-collapse-item>
                </van-collapse>
                <div class="hgyfkdatasupplyremark">
                  <van-field
                    v-model="item.hgyfk"
                    :label="'回顾及反馈频率'"
                    label-width="7em"
                    readonly
                    input-align="right"
                  />
                  <van-field
                    v-model="item.datasupply"
                    :label="'数据提供责任人'"
                    label-width="7em"
                    readonly
                    input-align="right"
                  />
                  <van-collapse
                    class="thrid_coll"
                    v-model="activeNames8"
                    @change="item.remarkstatus = !item.remarkstatus"
                  >
                    <van-collapse-item
                      :name="ind + 'remark' + idx"
                      :title="'备注'"
                    >
                      <div class="thrid_desc">
                        <div class="desp">{{ item.remark }}</div>
                      </div>
                    </van-collapse-item>
                  </van-collapse>
                </div>

                <!-- 自我描述 -->
                <van-collapse
                  class="thrid_coll"
                  v-model="item.activeNames5"
                  @change="changeTwo(item, 'zw')"
                  v-if="show_is_zp == 'true' && item.idtype != '定量'"
                >
                  <van-collapse-item
                    :name="key + 'zw' + index"
                    :title="$t('jxDataSocreDetail.txt9')"
                  >
                    <template #right-icon>
                      <div class="right-icon">
                        {{
                          `${
                            item.showzw
                              ? $t('jxDataSocreDetail.txt6')
                              : $t('jxDataSocreDetail.txt7')
                          }`
                        }}
                        <van-icon
                          :name="item.showzw ? 'arrow-up' : 'arrow-down'"
                        />
                      </div>
                    </template>
                    <div class="thrid_desc">
                      <!-- <div class="desp">{{item.zpdesc}}</div> -->
                      <van-field
                        class="brief"
                        v-model="item.zpdesc"
                        rows="5"
                        autosize
                        label
                        :readonly="!item.is_zp"
                        type="textarea"
                        show-word-limit
                        style="margin-bottom: 15px"
                      />
                      <!-- 指标自评附件 -->
                      <div
                        v-if="
                          (show_is_zp == 'true' || show_is_zp == true) &&
                            (up_detail_atfile == true ||
                              up_detail_atfile == 'true')
                        "
                        style="padding: 10px; border-top: 1px solid;"
                      >
                        <div style="text-align: center;margin-bottom: 10px;">
                          附件（最多15张）
                        </div>
                        <div
                          v-if="item.is_zp == 1"
                          style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
                        >
                          <div
                            v-for="(it, index) in item.zpAttFileList"
                            :key="index"
                            style="margin:0 8px 8px 0"
                          >
                            <van-badge>
                              <van-image
                                v-if="it.fileext.indexOf('pdf') == -1"
                                width="80"
                                height="80"
                                :src="it.url"
                                @click="imagePreview(item.zpAttFileList)"
                              />
                              <van-image
                                v-else
                                width="80"
                                height="80"
                                :src="require('@assets/img/xmind.png')"
                                @click="showPdf(it)"
                              />
                              <template #content>
                                <van-icon
                                  name="cross"
                                  class="badge-icon"
                                  @click="deleteAttFile(it, 'norm', 'zp')"
                                />
                              </template>
                            </van-badge>
                          </div>
                        </div>
                        <div
                          v-else
                          style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
                        >
                          <div
                            v-for="(it, index) in item.zpAttFileList"
                            :key="index"
                            style="margin:0 8px 8px 0"
                          >
                            <van-image
                              v-if="it.fileext.indexOf('pdf') == -1"
                              width="80"
                              height="80"
                              :src="it.url"
                              @click="imagePreview(item.zpAttFileList)"
                            />
                            <van-image
                              v-else
                              width="80"
                              height="80"
                              :src="require('@assets/img/xmind.png')"
                              @click="showPdf(it)"
                            />
                          </div>
                        </div>
                        <van-uploader
                          v-if="item.is_zp == 1"
                          accept="image/*, .pdf"
                          preview-size="80px"
                          v-model="item.zpFileList"
                          :max-count="15 - item.zpAttFileList.length"
                          :after-read="afterRead"
                        />
                      </div>
                    </div>
                  </van-collapse-item>
                </van-collapse>
                <!-- 直接上级描述 -->
                <van-collapse
                  class="thrid_coll"
                  v-model="item.activeNames6"
                  @change="changeTwo(item, 'zj')"
                  v-if="
                    show_is_zjp == 'true' &&
                      item.idtype != '定量' &&
                      expand_kpi_hide_zjsjms != 0
                  "
                >
                  <van-collapse-item
                    :name="key + 'zj' + index"
                    :title="$t('jxDataSocreDetail.txt10')"
                  >
                    <template #right-icon>
                      <div class="right-icon">
                        {{
                          `${
                            item.showzjdesp
                              ? $t('jxDataSocreDetail.txt6')
                              : $t('jxDataSocreDetail.txt7')
                          }`
                        }}
                        <van-icon
                          :name="item.showzjdesp ? 'arrow-up' : 'arrow-down'"
                        />
                      </div>
                    </template>
                    <div class="thrid_desc">
                      <!-- <div class="desp">{{item.zpdesc}}</div> -->
                      <van-field
                        class="brief"
                        v-model="item.zjdesc"
                        rows="5"
                        autosize
                        label
                        :readonly="!item.is_zjp"
                        type="textarea"
                        show-word-limit
                        style="margin-bottom: 15px"
                      />
                      <!-- 指标直接上级评附件 -->
                      <div style="padding: 10px; border-top: 1px solid;">
                        <div style="text-align: center;margin-bottom: 10px;">
                          附件（最多15张）
                        </div>
                        <div
                          v-if="item.is_zjp == 1"
                          style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
                        >
                          <div
                            v-for="(it, index) in item.zjAttFileList"
                            :key="index"
                            style="margin:0 8px 8px 0"
                          >
                            <van-badge>
                              <van-image
                                v-if="it.fileext.indexOf('pdf') == -1"
                                width="80"
                                height="80"
                                :src="it.url"
                                @click="imagePreview(item.zjAttFileList)"
                              />
                              <van-image
                                v-else
                                width="80"
                                height="80"
                                :src="require('@assets/img/xmind.png')"
                                @click="showPdf(it)"
                              />
                              <template #content>
                                <van-icon
                                  name="cross"
                                  class="badge-icon"
                                  @click="deleteAttFile(it, 'norm', 'zj')"
                                />
                              </template>
                            </van-badge>
                          </div>
                        </div>
                        <div
                          v-else
                          style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
                        >
                          <div
                            v-for="(it, index) in item.zjAttFileList"
                            :key="index"
                            style="margin:0 8px 8px 0"
                          >
                            <van-image
                              v-if="it.fileext.indexOf('pdf') == -1"
                              width="80"
                              height="80"
                              :src="it.url"
                              @click="imagePreview(item.zjAttFileList)"
                            />
                            <van-image
                              v-else
                              width="80"
                              height="80"
                              :src="require('@assets/img/xmind.png')"
                              @click="showPdf(it)"
                            />
                          </div>
                        </div>
                        <van-uploader
                          v-if="item.is_zjp == 1"
                          accept="image/*, .pdf"
                          preview-size="80px"
                          v-model="item.zjFileList"
                          :max-count="15 - item.zjAttFileList.length"
                          :after-read="afterRead"
                        />
                      </div>
                    </div>
                  </van-collapse-item>
                </van-collapse>
                <!-- 间接上级描述 -->
                <van-collapse
                  class="thrid_coll"
                  v-model="item.activeNames7"
                  @change="changeTwo(item, 'jj')"
                  v-if="
                    show_is_jjp == 'true' &&
                      item.idtype != '定量' &&
                      expand_kpi_hide_jjsjms != 0
                  "
                >
                  <van-collapse-item
                    :name="key + 'jj' + index"
                    :title="$t('jxDataSocreDetail.txt11')"
                  >
                    <template #right-icon>
                      <div class="right-icon">
                        {{
                          `${
                            item.showjjdesp
                              ? $t('jxDataSocreDetail.txt6')
                              : $t('jxDataSocreDetail.txt7')
                          }`
                        }}
                        <van-icon
                          :name="item.showjjdesp ? 'arrow-up' : 'arrow-down'"
                        />
                      </div>
                    </template>
                    <div class="thrid_desc">
                      <!-- <div class="desp">{{item.zpdesc}}</div> -->
                      <van-field
                        class="brief"
                        v-model="item.jjdesc"
                        rows="5"
                        autosize
                        label
                        :readonly="!item.is_jjp"
                        type="textarea"
                        show-word-limit
                        style="margin-bottom: 15px"
                      />
                      <!-- 指标间接上级评附件 -->
                      <div
                        v-if="
                          (show_is_jjp == 'true' || show_is_jjp == true) &&
                            (up_detail_atfile == true ||
                              up_detail_atfile == 'true')
                        "
                        style="padding: 10px; border-top: 1px solid;"
                      >
                        <div style="text-align: center;margin-bottom: 10px;">
                          附件（最多15张）
                        </div>
                        <div
                          v-if="item.is_jjp == 1"
                          style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
                        >
                          <div
                            v-for="(it, index) in item.jjAttFileList"
                            :key="index"
                            style="margin:0 8px 8px 0"
                          >
                            <van-badge>
                              <van-image
                                v-if="it.fileext.indexOf('pdf') == -1"
                                width="80"
                                height="80"
                                :src="it.url"
                                @click="imagePreview(item.jjAttFileList)"
                              />
                              <van-image
                                v-else
                                width="80"
                                height="80"
                                :src="require('@assets/img/xmind.png')"
                                @click="showPdf(it)"
                              />
                              <template #content>
                                <van-icon
                                  name="cross"
                                  class="badge-icon"
                                  @click="deleteAttFile(it, 'norm', 'jj')"
                                />
                              </template>
                            </van-badge>
                          </div>
                        </div>
                        <div
                          v-else
                          style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
                        >
                          <div
                            v-for="(it, index) in item.jjAttFileList"
                            :key="index"
                            style="margin:0 8px 8px 0"
                          >
                            <van-image
                              v-if="it.fileext.indexOf('pdf') == -1"
                              width="80"
                              height="80"
                              :src="it.url"
                              @click="imagePreview(item.jjAttFileList)"
                            />
                            <van-image
                              v-else
                              width="80"
                              height="80"
                              :src="require('@assets/img/xmind.png')"
                              @click="showPdf(it)"
                            />
                          </div>
                        </div>
                        <van-uploader
                          v-if="item.is_jjp == 1"
                          accept="image/*, .pdf"
                          preview-size="80px"
                          v-model="item.jjFileList"
                          :max-count="15 - item.jjAttFileList.length"
                          :after-read="afterRead"
                        />
                      </div>
                    </div>
                  </van-collapse-item>
                </van-collapse>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-collapse-item>
        <!-- 自评附件 -->
        <van-collapse-item
          v-if="
            (checkflag == 1 || checkflag == 2 || checkflag == 3) &&
              (up_master_atfile == true || up_master_atfile == 'true')
          "
          class="zp_coll_item"
          :title="'自评附件'"
          :name="'active' + (resData.length + 1)"
        >
          <div style="padding: 10px;">
            <div style="text-align: center;margin-bottom: 10px;">
              附件（最多15张）
            </div>
            <div
              v-if="checkflag == 1"
              style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
            >
              <div
                v-for="(it, index) in zpAttFileList"
                :key="index"
                style="margin:0 8px 8px 0"
              >
                <van-badge>
                  <van-image
                    v-if="it.fileext.indexOf('pdf') == -1"
                    width="80"
                    height="80"
                    :src="it.url"
                    @click="imagePreview(zpAttFileList)"
                  />
                  <van-image
                    v-else
                    width="80"
                    height="80"
                    :src="require('@assets/img/xmind.png')"
                    @click="showPdf(it)"
                  />
                  <template #content>
                    <van-icon
                      name="cross"
                      class="badge-icon"
                      @click="deleteAttFile(it, 'plan', 'zp')"
                    />
                  </template>
                </van-badge>
              </div>
            </div>
            <div
              v-else
              style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
            >
              <div
                v-for="(it, index) in zpAttFileList"
                :key="index"
                style="margin:0 8px 8px 0"
              >
                <van-image
                  v-if="it.fileext.indexOf('pdf') == -1"
                  width="80"
                  height="80"
                  :src="it.url"
                  @click="imagePreview(zpAttFileList)"
                />
                <van-image
                  v-else
                  width="80"
                  height="80"
                  :src="require('@assets/img/xmind.png')"
                  @click="showPdf(it)"
                />
              </div>
            </div>
            <van-uploader
              v-if="checkflag == 1"
              accept="image/*, .pdf"
              preview-size="80px"
              v-model="zpFileList"
              :max-count="zpmaxCount"
              :after-read="afterRead"
            />
          </div>
        </van-collapse-item>
        <!-- 直接上级附件 -->
        <van-collapse-item
          v-if="
            (checkflag == 2 || checkflag == 3) &&
              (up_master_atfile == true || up_master_atfile == 'true')
          "
          class="zj_coll_item"
          :title="'直接上级附件'"
          :name="'active' + (resData.length + 2)"
        >
          <div style="padding: 10px;">
            <div style="text-align: center;margin-bottom: 10px;">
              附件（最多15张）
            </div>
            <div
              v-if="checkflag == 2"
              style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
            >
              <div
                v-for="(it, index) in zjAttFileList"
                :key="index"
                style="margin:0 8px 8px 0"
              >
                <van-badge>
                  <van-image
                    v-if="it.fileext.indexOf('pdf') == -1"
                    width="80"
                    height="80"
                    :src="it.url"
                    @click="imagePreview(zjAttFileList)"
                  />
                  <van-image
                    v-else
                    width="80"
                    height="80"
                    :src="require('@assets/img/xmind.png')"
                    @click="showPdf(it)"
                  />
                  <template #content>
                    <van-icon
                      name="cross"
                      class="badge-icon"
                      @click="deleteAttFile(it, 'plan', 'zj')"
                    />
                  </template>
                </van-badge>
              </div>
            </div>
            <div
              v-else
              style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
            >
              <div
                v-for="(it, index) in zjAttFileList"
                :key="index"
                style="margin:0 8px 8px 0"
              >
                <van-image
                  v-if="it.fileext.indexOf('pdf') == -1"
                  width="80"
                  height="80"
                  :src="it.url"
                  @click="imagePreview(zjAttFileList)"
                />
                <van-image
                  v-else
                  width="80"
                  height="80"
                  :src="require('@assets/img/xmind.png')"
                  @click="showPdf(it)"
                />
              </div>
            </div>
            <van-uploader
              v-if="checkflag == 2"
              accept="image/*, .pdf"
              preview-size="80px"
              v-model="zjFileList"
              :max-count="zjmaxCount"
              :after-read="afterRead"
            />
          </div>
        </van-collapse-item>
        <!-- 间接上级附件 -->
        <van-collapse-item
          v-if="
            checkflag == 3 &&
              (up_master_atfile == true || up_master_atfile == 'true')
          "
          class="jj_coll_item"
          :title="'间接上级附件'"
          :name="'active' + (resData.length + 3)"
        >
          <div style="padding: 10px;">
            <div style="text-align: center;margin-bottom: 10px;">
              附件（最多15张）
            </div>
            <div
              v-if="checkflag == 2"
              style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
            >
              <div
                v-for="(it, index) in jjAttFileList"
                :key="index"
                style="margin:0 8px 8px 0"
              >
                <van-badge>
                  <van-image
                    v-if="it.fileext.indexOf('pdf') == -1"
                    width="80"
                    height="80"
                    :src="it.url"
                    @click="imagePreview(jjAttFileList)"
                  />
                  <van-image
                    v-else
                    width="80"
                    height="80"
                    :src="require('@assets/img/xmind.png')"
                    @click="showPdf(it)"
                  />
                  <template #content>
                    <van-icon
                      name="cross"
                      class="badge-icon"
                      @click="deleteAttFile(it, 'plan', 'jj')"
                    />
                  </template>
                </van-badge>
              </div>
            </div>
            <div
              v-else
              style="display:flex;flex-wrap: wrap;justify-content: flex-start;"
            >
              <div
                v-for="(it, index) in jjAttFileList"
                :key="index"
                style="margin:0 8px 8px 0"
              >
                <van-image
                  v-if="it.fileext.indexOf('pdf') == -1"
                  width="80"
                  height="80"
                  :src="it.url"
                  @click="imagePreview(jjAttFileList)"
                />
                <van-image
                  v-else
                  width="80"
                  height="80"
                  :src="require('@assets/img/xmind.png')"
                  @click="showPdf(it)"
                />
              </div>
            </div>
            <van-uploader
              v-if="checkflag == 3"
              accept="image/*, .pdf"
              preview-size="80px"
              v-model="jjFileList"
              :max-count="jjmaxCount"
              :after-read="afterRead"
            />
          </div>
        </van-collapse-item>
      </van-collapse>
      <!-- v-if="(show_is_zjp == 'true' ||show_is_zjp == true) && (usezj_forid == false || usezj_forid == 'false')" -->
      <div
        v-if="(show_is_zjp == 'true' || show_is_zjp == true) && checkflag == 2"
      >
        <div style="font-size: 0.42667rem;font-weight: bold;">直接评价</div>
        <van-field
          class="brief"
          v-model="zj_pj_memo"
          rows="5"
          autosize
          label
          type="textarea"
          show-word-limit
          style="margin-bottom: 15px"
        />
      </div>
      <!-- v-if="(show_is_jjp == 'true' ||show_is_jjp == true) && (usejj_forid == false || usejj_forid == 'false')" -->
      <div
        v-if="(show_is_jjp == 'true' || show_is_jjp == true) && checkflag == 3"
      >
        <div style="font-size: 0.42667rem;font-weight: bold;">间接评价</div>
        <van-field
          class="brief"
          v-model="jj_pj_memo"
          rows="5"
          autosize
          label
          type="textarea"
          show-word-limit
          style="margin-bottom: 15px"
        />
      </div>
      <div
        class="check_grade"
        v-if="
          can_edit_khres == 'true' ||
            can_edit_khres == true ||
            can_edit_khmoney == 'true' || can_edit_khmoney == true
        "
      >
        <div class="select_container">
          <div
            class="select_input ct1"
            v-if="can_edit_khres == 'true' || can_edit_khres == true"
          >
            <h3>{{ '考核等级' }}</h3>
            <div
              style="display: flex;align-items: center;justify-content: center;"
            >
              <van-field
                readonly
                clickable
                name="picker"
                :value="khres"
                placeholder="请选择"
                @click="showkhresPicker = true"
              />
              <van-popup v-model="showkhresPicker" round position="bottom">
                <van-picker
                  show-toolbar
                  :columns="khrescolumns"
                  @confirm="onkhresConfirm"
                  @cancel="showkhresPicker = false"
                />
              </van-popup>
            </div>
          </div>
          <div
            class="select_input ct2"
            v-if="can_edit_khmoney == 'true' || can_edit_khmoney == true"
          >
            <h3>{{ '考核奖金' }}</h3>
            <div
              style="display: flex;align-items: center;justify-content: center;"
            >
              <van-field v-model="khmoney" type="number" placeholder="请输入" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-statistics">
      <div class="footer-list">
        <strong class="kh_Word">{{ $t('jxDataSocreDetail.txt16') }}</strong>
        <strong class="kh_Socre"
          >{{ anum }}{{ $t('jxDataSocreDetail.txt5') }}</strong
        >
      </div>
      <div class="footer-button">
        <van-button
          @click="submitDataEvent"
          type="primary"
          block
          color="#2B8DF0"
          >{{ $t('jxDataSocreDetail.txt17') }}</van-button
        >
      </div>
    </div>
    <!-- pdf内置播放器 -->
    <pdfView :pdf="pdf"></pdfView>
  </div>
</template>
<script>
import { Toast, Dialog } from 'vant'
import { ImagePreview } from 'vant'
import { deleteFile, GetFilterDropDownList } from '@api/wx.js'
import {
  getKpiPlanIndicatorDetail,
  saveKpiPlanIndicatorDetail,
  upLoadBase64FileFixName,
  saveKpiPjMemo
} from '@api/wxjx.js'
import { getModuleAttFileList } from '@api/wxzp.js'
import { createGguid } from '@/utils'
import pdfView from '@components/pdfView/index.vue'
export default {
  components: {
    pdfView
  },
  name: 'jxDataSocre',
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    const can_edit_khres = this.$route.query.can_edit_khres
    const can_edit_khmoney = this.$route.query.can_edit_khmoney
    const khres1 = this.$route.query.khres
    const khmoney1 = this.$route.query.khmoney
    return {
      userInfo,
      can_edit_khres,
      can_edit_khmoney,
      activeNames: ['active1'],
      activeNames2: ['name1'],
      autoid: this.$route.query.autoid,
      checkflag: this.$route.query.checkflag,
      show_is_zp: this.$route.query.is_zp,
      show_is_zjp: this.$route.query.is_zjp,
      show_is_jjp: this.$route.query.is_jjp,
      show_can_pj_dl: this.$route.query.can_pj_dl,
      usejj_forid: this.$route.query.usejj_forid,
      usezj_forid: this.$route.query.usezj_forid,
      zj_pj_memo: this.$route.query.zj_pj_memo, // 直接上级评价内容；
      jj_pj_memo: this.$route.query.jj_pj_memo, // 间接上级评价内容；
      resData: [],
      anum: 0,
      tjList: [],
      activeNames3: [],
      activeNames4: [],
      activeNames5: [],
      activeNames6: [],
      activeNames7: [],
      zpFileList: [],
      zjFileList: [],
      jjFileList: [],
      zpAttFileList: [], // 获取到的自评附件
      zjAttFileList: [], // 获取到的直接上级附件
      jjAttFileList: [], // 获取到的间接上级附件
      pdf: '',
      up_detail_atfile: this.$route.query.up_detail_atfile,
      up_master_atfile: this.$route.query.up_master_atfile,
      activeNames8: [],
      activeNames9: [],
      activeNames10: [],
      // zj_pj_memo: "", // 直接上级评价内容；
      // jj_pj_memo: "", // 间接上级评价内容；
      //是否显示直接上级描述（前提是启用直接上级的时候，如果不希望显示这个描述，可以通过该参数隐藏）window.expand_kpi_hide_zjsjms="0"
      expand_kpi_hide_zjsjms: window.expand_kpi_hide_zjsjms,

      //是否显示间接上级描述（前提是启用间接上级的时候，如果不希望显示这个描述，可以通过该参数隐藏）window.expand_kpi_hide_jjsjms="0"
      expand_kpi_hide_jjsjms: window.expand_kpi_hide_jjsjms,

      khres: khres1,
      khmoney: khmoney1,
      khrescolumns: [],
      showkhresPicker: false
    }
  },
  computed: {
    // 附件数量最多不超过15张
    zpmaxCount() {
      return 15 - this.zpAttFileList.length
    },
    zjmaxCount() {
      return 15 - this.zjAttFileList.length
    },
    jjmaxCount() {
      return 15 - this.jjAttFileList.length
    }
  },
  watch: {
    resData: {
      deep: true,
      handler(n) {
        let a = 0
        for (let i = 0; i < this.resData.length; i++) {
          for (let k = 0; k < this.resData[i].data.length; k++) {
            let data = this.resData[i].data[k]
            if (
              !data.can_over_tgvalue &&
              (data.itemscore > data.tgvalue ||
                data.zjsj_score > data.tgvalue ||
                data.jjsj_score > data.tgvalue)
            ) {
              Toast(data.itemname + '--' + this.$t('jxDataSocreDetail.txt18'))
              return false
            }
            if (data.totalscore || data.totalscore == 0) {
              let b = parseFloat(data.totalscore)
              console.log(b)
              a = a + Math.floor(b * 100) / 100
            }
          }
        }
        console.log(a)
        this.anum = Math.floor(a * 100) / 100
        // 给指标附件改参数
        n.forEach(item => {
          if (item.data.length > 0) {
            item.data.forEach(it => {
              if (it.zpFileList && it.zpFileList.length > 0) {
                it.zpFileList.forEach(el => {
                  const guid = createGguid()
                  el.fileext = el.file.type.split('/')[1]
                  el.filename = '1_' + guid
                  el.filebase64str = el.content.split('base64,')[1]
                })
              }
              if (it.zjFileList && it.zjFileList.length > 0) {
                it.zjFileList.forEach(el => {
                  const guid = createGguid()
                  el.fileext = el.file.type.split('/')[1]
                  el.filename = '2_' + guid
                  el.filebase64str = el.content.split('base64,')[1]
                })
              }
              if (it.jjFileList && it.jjFileList.length > 0) {
                it.jjFileList.forEach(el => {
                  const guid = createGguid()
                  el.fileext = el.file.type.split('/')[1]
                  el.filename = '3_' + guid
                  el.filebase64str = el.content.split('base64,')[1]
                })
              }
              if (it.tjFileList && it.tjFileList.length > 0) {
                it.tjFileList.forEach(el => {
                  const guid = createGguid()
                  el.fileext = el.file.type.split('/')[1]
                  el.filename = '4_' + guid
                  el.filebase64str = el.content.split('base64,')[1]
                })
              }
            })
          }
        })
      }
    },
    zpFileList: {
      handler(n) {
        n.forEach(el => {
          const guid = createGguid()
          el.fileext = el.file.type.split('/')[1]
          el.filename = '1_' + guid
          el.filebase64str = el.content.split('base64,')[1]
        })
        // console.log(n, 'zpFileList');
      }
    },
    zjFileList: {
      handler(n) {
        n.forEach(el => {
          const guid = createGguid()
          el.fileext = el.file.type.split('/')[1]
          el.filename = '2_' + guid
          el.filebase64str = el.content.split('base64,')[1]
        })
        // console.log(n, 'zjFileList');
      }
    },
    jjFileList: {
      handler(n) {
        n.forEach(el => {
          const guid = createGguid()
          el.fileext = el.file.type.split('/')[1]
          el.filename = '3_' + guid
          el.filebase64str = el.content.split('base64,')[1]
        })
        // console.log(n, 'jjFileList');
      }
    }
  },
  created() {
    getKpiPlanIndicatorDetail({
      username: this.userInfo.username,
      mautoid: this.autoid
    }).then(res => {
      this.tjList = res.itemlist
      this.resData = this.classifyArr(res.data, 'itemtype')
      for (let i = 0; i < this.resData.length; i++) {
        this.activeNames.push('active' + (i + 1))
        this.activeNames.push('active' + (this.resData.length + 1))
        this.activeNames.push('active' + (this.resData.length + 2))
        this.activeNames.push('active' + (this.resData.length + 3))
        let activeNames2 = []
        for (let d = 0; d < this.resData[i].data.length; d++) {
          if (window.expand_kpi_item == 1 || window.expand_kpi_item == 2) {
            activeNames2.push(i + 'name' + (d + 1))
          }
          // 评分规则展开
          let activeNames3 = []
          if (window.expand_kpi_item == 1) {
            activeNames3.push(i + 'gz' + d)
          }
          this.resData[i].data[d].activeNames3 = activeNames3
          // 自我描述展开
          let activeNames5 = []
          if (
            this.resData[i].data[d].is_zp == 1 &&
            (window.expand_kpi_item == 1 || window.expand_kpi_item == 2)
          ) {
            activeNames5.push(i + 'zw' + d)
          }
          this.resData[i].data[d].activeNames5 = activeNames5
          // 直接上级描述展开
          let activeNames6 = []
          if (
            this.resData[i].data[d].is_zjp == 1 &&
            window.expand_kpi_item == 1
          ) {
            activeNames6.push(i + 'zj' + d)
          }
          this.resData[i].data[d].activeNames6 = activeNames6
          // 间接上级描述展开
          let activeNames7 = []
          if (
            this.resData[i].data[d].is_jjp == 1 &&
            window.expand_kpi_item == 1
          ) {
            activeNames7.push(i + 'jj' + d)
          }
          this.resData[i].data[d].activeNames7 = activeNames7
        }
        this.resData[i].activeNames2 = activeNames2
      }
      // console.log(this.resData, 'resDataresData');
    })
    this.getModuleAttFileListForPlan()

    this.getkhresPicker()
  },
  methods: {
    // 打开不同意面试原因弹窗
    getkhresPicker() {
      this.khrescolumns = []
      GetFilterDropDownList({
        moduleno: 508,
        username: this.userInfo.username,
        listsql: '@考核等级定义'
      }).then(res => {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.khrescolumns.push(res.data[i].dicvalue)
          }
        }
      })
    },
    // 考核等级下拉选回调
    onkhresConfirm(value) {
      this.khres = value
      this.showkhresPicker = false
    },
    // 删除附件
    deleteAttFile(file, type, str) {
      console.log(file, 'filefilefile')
      if (file.sfilename) {
        Dialog.confirm({
          // title: '标题',
          message: '确认删除该附件吗？'
        })
          .then(() => {
            deleteFile({
              dflag: 2,
              moduleno: file.moduleno,
              autoid: file.mautoid,
              filename: file.sfilename,
              fileext: file.fileext, // .split('.')[1],
              downloadpath: 'AttachFile'
            }).then(res => {
              if (type == 'plan') {
                // 删除方案的附件
                if (str == 'zp') {
                  this.zpAttFileList = this.zpAttFileList.filter(it => {
                    return it.autoid != file.autoid
                  })
                } else if (str == 'zj') {
                  this.zjAttFileList = this.zjAttFileList.filter(it => {
                    return it.autoid != file.autoid
                  })
                } else if (str == 'jj') {
                  this.jjAttFileList = this.jjAttFileList.filter(it => {
                    return it.autoid != file.autoid
                  })
                }
              } else {
                // 删除指标的附件
                this.resData.forEach(item => {
                  if (item.data.length > 0) {
                    item.data.forEach(it => {
                      if (it.autoid == file.mautoid) {
                        if (str == 'zp') {
                          it.zpAttFileList = it.zpAttFileList.filter(el => {
                            return el.autoid != file.autoid
                          })
                        } else if (str == 'zj') {
                          it.zjAttFileList = it.zjAttFileList.filter(el => {
                            return el.autoid != file.autoid
                          })
                        } else if (str == 'jj') {
                          it.jjAttFileList = it.jjAttFileList.filter(el => {
                            return el.autoid != file.autoid
                          })
                        } else if (str == 'tj') {
                          it.tjAttFileList = it.tjAttFileList.filter(el => {
                            return el.autoid != file.autoid
                          })
                        }
                      }
                    })
                  }
                })
              }
              this.$forceUpdate()
            })
          })
          .catch(() => {
            // on cancel
          })
      }
    },
    // 预览PDF
    showPdf(it) {
      this.pdf = it.url + '?time=' + new Date().getTime()
    },
    // 预览图片
    imagePreview(list) {
      let imgs = []
      list.forEach(el => {
        imgs.push(el.url)
      })
      ImagePreview(imgs)
    },
    afterRead(file) {
      // console.log(file, 'file');
    },
    // 上传附件
    upLoadBase64File(fileList, moduleno, autoid) {
      for (const file of fileList) {
        let data = {
          filebase64str: file.filebase64str,
          upflag: 2,
          moduleno: moduleno,
          autoid: autoid,
          uploadpath: 'AttachFile',
          filename: file.filename,
          fileext: file.fileext,
          username: this.userInfo.username
        }
        upLoadBase64FileFixName(data).then(res => {})
      }
    },
    // 获取整个方案的附件列表
    getModuleAttFileListForPlan() {
      let moduleno = 508
      let mautoid = this.autoid
      let lists = []
      getModuleAttFileList({
        moduleno: moduleno,
        mautoid: mautoid,
        username: this.userInfo.username
      }).then(res => {
        lists = res.module_atlist
        if (lists.length > 0) {
          lists.forEach(el => {
            if (el.sfilename.split('_')[0] == 1) {
              this.zpAttFileList.push(el)
            } else if (el.sfilename.split('_')[0] == 2) {
              this.zjAttFileList.push(el)
            } else if (el.sfilename.split('_')[0] == 3) {
              this.jjAttFileList.push(el)
            }
          })
        }
      })
    },
    // 附件数据处理
    filehandleData(list) {
      let groups = [] // 分组[后缀]
      let res = [] // 第一次分类的集合
      if (list.module_atlist) {
        list.module_atlist.forEach(item => {
          let type = ''
          let groupName = !item.aftype ? '' : item.aftype
          const ext = item.fileext.slice(1) // 后缀
          let url = ''
          if ('doc|docx'.includes(ext)) {
            name = 'WORD文件'
            type = 'doc'
            url = require('@assets/img/word.png')
          } else if ('xlsx|xls'.includes(ext)) {
            name = 'EXCEL文件'
            type = 'xls'
            url = require('@assets/img/excel2.png')
          } else if ('ppt|pptx'.includes(ext)) {
            name = 'PPT文件'
            type = 'ppt'
            url = require('@assets/img/ppt2.png')
          } else if ('pdf'.includes(ext)) {
            name = 'PDF文件'
            type = 'pdf'
            url = require('@assets/img/picture.png')
          } else if ('png|jpg|jpeg|emp'.includes(ext)) {
            name = '图片文件'
            type = 'pic'
            url = require('@assets/img/picture.png')
          } else if ('zip|rar'.includes(ext)) {
            name = '压缩文件'
            type = 'zip'
            url = require('@assets/img/zip.png')
          } else {
            name = '其他文件'
            type = 'default'
            url = require('@assets/img/xmind.png')
          }
          item.type = type
          // item.url = url
          item.isImage = true
          res.push(item)
        })
      }
      if (list.other_atlist) {
        list.other_atlist.forEach(item => {
          let type = ''
          const ext = item.fileext.slice(1) // 后缀
          let groupName = !item.aftype ? '' : item.aftype
          let url = ''
          if ('doc|docx'.includes(ext)) {
            name = 'WORD文件'
            type = 'doc'
            url = require('@assets/img/word.png')
          } else if ('xlsx|xls'.includes(ext)) {
            name = 'EXCEL文件'
            type = 'xls'
            url = require('@assets/img/excel2.png')
          } else if ('ppt|pptx'.includes(ext)) {
            name = 'PPT文件'
            type = 'ppt'
            url = require('@assets/img/ppt2.png')
          } else if ('pdf'.includes(ext)) {
            name = 'PDF文件'
            type = 'pdf'
            url = require('@assets/img/picture.png')
          } else if ('png|jpg|jpeg|emp'.includes(ext)) {
            name = '图片文件'
            type = 'pic'
            url = require('@assets/img/picture.png')
          } else if ('zip|rar'.includes(ext)) {
            name = '压缩文件'
            type = 'zip'
            url = require('@assets/img/zip.png')
          } else {
            name = '其他文件'
            type = 'default'
            url = require('@assets/img/xmind.png')
          }
          item.type = type
          // item.url = url
          item.isImage = true
          res.push(item)
        })
      }
      return res
    },
    // 第二层Collapse change
    changeTwo(item, type) {
      if (item) {
        if (type == 'gz') {
          item.showgz = !item.showgz
        } else if (type == 'zw') {
          item.showzw = !item.showzw
        } else if (type == 'zj') {
          item.showzjdesp = !item.showzjdesp
        } else if (type == 'jj') {
          item.showjjdesp = !item.showjjdesp
        }
      }
      // 点击第二层Collapse时刷新
      this.$forceUpdate()
    },
    onClickLeft() {
      this.$router.push('/jxDataSocre')
    },
    classifyArr(arr, param) {
      let map = {},
        dest = []
      for (var i = 0; i < arr.length; i++) {
        let ai = arr[i]
        ai.itemdesc = ai.itemdesc ? ai.itemdesc.replace(/\\n/g, '\n') : ''
        ai.showgz = false // 显示评分规则
        ai.showtj = false // 显示统计项目
        ai.showzw = false
        // this.show_is_zp == 'true' || this.show_is_zp == true ? true : false // 显示自我描述
        ai.showzjdesp = false
        // this.show_is_zjp == 'true' || this.show_is_zjp == true ? true : false // 显示直接上级描述
        ai.showjjdesp = false
        // this.show_is_jjp == 'true' || this.show_is_jjp == true ? true : false // 显示间接上级描述
        ai.child = [] // 统计项目
        ai.showzpAtfile = false // 显示自评附件
        ai.showzjAtfile = false // 显示直接上级附件
        ai.showjjAtfile = false // 显示间接上级附件
        ai.zpAttFileList = [] // 后台获取的自评附件
        ai.zjAttFileList = [] // 后台获取的直接上级附件
        ai.jjAttFileList = [] // 后台获取的间接上级附件
        ai.tjAttFileList = [] // 后台获取的统计项目附件
        ai.zpFileList = [] // 前端储存的自评附件
        ai.zjFileList = [] // 前端储存的直接上级附件
        ai.jjFileList = [] // 前端储存的间接上级附件
        ai.tjFileList = [] // 前端储存的统计项目附件
        // 获取指标附件
        const moduleno = 508001
        getModuleAttFileList({
          moduleno: moduleno,
          mautoid: ai.autoid,
          username: this.userInfo.username
        }).then(res => {
          if (res.module_atlist.length > 0) {
            res.module_atlist.forEach(el => {
              if (el.sfilename.split('_')[0] == 1) {
                ai.zpAttFileList.push(el)
              } else if (el.sfilename.split('_')[0] == 2) {
                ai.zjAttFileList.push(el)
              } else if (el.sfilename.split('_')[0] == 3) {
                ai.jjAttFileList.push(el)
              } else if (el.sfilename.split('_')[0] == 4) {
                ai.tjAttFileList.push(el)
              }
            })
          }
        })
        // 统计项目
        if (this.tjList.length > 0) {
          this.tjList.forEach(it => {
            if (ai.itemautoid == it.itemautoid) {
              ai.child.push(it)
            }
          })
        }
        // 分组
        if (ai[param] && !map[ai[param]]) {
          dest.push({ name: ai[param], data: [ai] })
          map[ai[param]] = ai
        } else {
          for (var j = 0; j < dest.length; j++) {
            let dj = dest[j]
            if (dj.name == ai[param]) {
              dj.data.push(ai)
              break
            }
          }
        }
      }
      console.log(dest, 'sest')
      return dest
    },
    // 提交
    submitDataEvent() {
      Dialog.confirm({
        title: this.$t('jxDataSocreDetail.txt1'),
        message: this.$t('jxDataSocreDetail.txt23')
      })
        .then(() => {
          let arr = []
          for (let i = 0; i < this.resData.length; i++) {
            for (let k = 0; k < this.resData[i].data.length; k++) {
              let data = this.resData[i].data[k]
              if (
                !data.can_over_tgvalue &&
                (data.itemscore > data.tgvalue ||
                  data.zjsj_score > data.tgvalue ||
                  data.jjsj_score > data.tgvalue)
              ) {
                Toast(data.itemname + '--' + this.$t('jxDataSocreDetail.txt19'))
                return false
              }
              let obj = {
                autoid: data.autoid,
                zpdesc: data.zpdesc,
                jjdesc: data.jjdesc,
                zjdesc: data.zjdesc,
                itemscore: data.itemscore,
                zjsj_score: data.zjsj_score,
                jjsj_score: data.jjsj_score
              }
              arr.push(obj)
            }
          }
          saveKpiPlanIndicatorDetail({
            username: this.userInfo.username,
            data: arr
          }).then(res => {
            console.log(res)
            if (res.data[0].info) {
              Toast(res.data[0].info)
            } else {
              // 上传方案的附件
              if (this.zpFileList.length > 0) {
                this.upLoadBase64File(this.zpFileList, 508, this.autoid)
              }
              if (this.zjFileList.length > 0) {
                this.upLoadBase64File(this.zjFileList, 508, this.autoid)
              }
              if (this.jjFileList.length > 0) {
                this.upLoadBase64File(this.jjFileList, 508, this.autoid)
              }

              // 上传指标的附件
              this.resData.forEach(item => {
                if (item.data.length > 0) {
                  item.data.forEach(it => {
                    if (it.zpFileList && it.zpFileList.length > 0) {
                      this.upLoadBase64File(it.zpFileList, 508001, it.autoid)
                    }
                    if (it.zjFileList && it.zjFileList.length > 0) {
                      this.upLoadBase64File(it.zjFileList, 508001, it.autoid)
                    }
                    if (it.jjFileList && it.jjFileList.length > 0) {
                      this.upLoadBase64File(it.jjFileList, 508001, it.autoid)
                    }
                    if (it.tjFileList && it.tjFileList.length > 0) {
                      this.upLoadBase64File(it.tjFileList, 508001, it.autoid)
                    }
                  })
                }
              })

              if (
                (this.show_is_zjp == 'true' || this.show_is_zjp == true) &&
                this.checkflag == 2
              ) {
                saveKpiPjMemo({
                  autoid: this.autoid,
                  username: this.userInfo.username,
                  pjflag: 1,
                  pjmemo: this.zj_pj_memo,
                  khres: this.khres,
                  khmoney: this.khmoney || 0
                }).then(res => {})
              }
              if (
                (this.show_is_jjp == 'true' || this.show_is_jjp == true) &&
                this.checkflag == 3
              ) {
                saveKpiPjMemo({
                  autoid: this.autoid,
                  username: this.userInfo.username,
                  pjflag: 2,
                  pjmemo: this.jj_pj_memo,
                  khres: this.khres,
                  khmoney: this.khmoney || 0
                }).then(res => {})
              }

              Toast(this.$t('module.SaveSuc'))
              this.$router.push('/jxDataSocre')
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>
<style lang="less" scoped>
.jxDataSocre {
  width: 100%;
  .van-nav-bar {
    background: #2b8df0;
    ::v-deep .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    ::v-deep .van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .container {
    width: 100%;
    padding: 30px;
    .van-collapse-item--border::after {
      border: 0 !important;
    }
    /deep/.van-collapse-item {
      .van-collapse-item__wrapper {
        background: #fff !important;
      }
      [class*='van-hairline']::after {
        border: 0 !important;
      }

      margin-bottom: 30px;
      .van-cell::after {
        border-bottom: 0 !important;
      }
      .van-collapse-item__title {
        background: #2b8df0;
        padding: 10px 15px;
        text-align: center;
        border-radius: 12px 12px 0 0;
        .van-cell__title span {
          color: #fff;
          font-size: 32px;
          font-weight: bold;
        }
        .van-cell__right-icon {
          color: #fff;
          font-size: 32px;
        }
      }
      .van-collapse-item__content {
        padding: 0 !important;
      }
      .van-collapse-item--border::after {
        left: 0 !important;
        right: 0 !important;
      }
      .childCollapse {
        .van-collapse-item__title {
          background: #fff !important;
          padding-bottom: 5px;
          text-align: left;
          padding: 20px 30px;
          border-radius: unset;
          .van-cell__title {
            color: #333 !important;
            font-size: 32px;
            font-weight: bold;
            .second_title {
              display: flex;
              justify-content: space-between;
              .second_name {
                width: 76%;
                text-align: left;
                font-weight: bold;
                .ic {
                  padding: 5px;
                  font-size: 22px;
                  border-radius: 5px;
                }
                .dl {
                  background: #dffadc;
                  color: #087908;
                }
                .dx {
                  background: #fde5d3;
                  color: #e85200;
                }
              }
              .second_score {
                color: #2b8df0;
                margin-right: 10px;
              }
            }
          }
          .van-cell__right-icon {
            color: #333 !important;
            font-size: 32px;
          }
        }
        .van-collapse-item__wrapper {
          padding: 0 0.42667rem;
        }
        .thrid_coll {
          .van-collapse-item {
            padding: 20px 0;
            margin-bottom: 0;
            border-radius: unset;
            border-bottom: unset;
            .van-cell::after {
              border-bottom: unset;
            }
            .van-collapse-item__title {
              text-align: left;
              padding: 0;
              .van-cell__title {
                span {
                  color: #999999 !important;
                  font-size: 30px;
                }
              }
              .right-icon {
                display: flex;
                align-items: center;
                color: #2b8df0;
                font-size: 24px;
                .van-icon {
                  color: #2b8df0;
                }
              }
            }
            .van-collapse-item__wrapper {
              padding: 0;
              .van-collapse-item__content {
                padding: 0;
                .thrid_desc {
                  // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                  // border-radius: 12px;
                  margin: 16px 4px 4px 4px;
                  .desp {
                    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                    border-radius: 12px;
                    padding: 20px;
                    color: #666666;
                    font-size: 24px;
                    min-height: 5em;
                    .Sta_poj {
                      padding-bottom: 12px;
                      color: #333333;
                      display: flex;
                      justify-content: space-between;
                      border-bottom: 0.02667rem solid #ebedf0;
                    }
                  }
                  .brief {
                    border-radius: 12px;
                  }
                }
                // .thrid_Sta {
                //   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                //   border-radius: 12px;
                //   margin: 16px 4px 4px 4px;
                //   min-height: 5em;

                // }
              }
            }
          }
        }
      }
    }
    /deep/ .zp_coll_item {
      .van-cell {
        background: #ff8000;
      }
    }
    /deep/ .zj_coll_item {
      .van-cell {
        background: #04e1a4;
      }
    }
    /deep/ .jj_coll_item {
      .van-cell {
        background: #b463f2;
      }
    }
    .childCollapse {
      .van-collapse-item--border::after {
        border-top: 0.02667rem solid #ebedf0 !important;
      }
    }
    .tips_container {
      padding: 10px 18px;
      width: 100%;

      .van-field {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border: 0;
      }
    }
    .select_container {
      width: 100%;
      display: flex;
      // justify-content: space-around;
      justify-content: start;
      margin-top: 0.4rem;
      .select_input {
        // width: 25%;
        // width: 33%;
        flex: 1;
        div {
          text-align: center;
        }
        h3 {
          margin: 0;
          font-size: 28px;
          color: #666;
          text-align: center;
        }
        input {
          // width: 80%;
          width: 90%;
          height: 66px;
          background: #ffffff;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
          border-radius: 10px;
          border: 0;
          margin-bottom: 6px;
          margin-top: 12px;
          text-align: center;
          color: #2b8df0;
          font-size: 36px;
        }
      }
    }
    .khf_shabox {
      margin-bottom: 20px;
      border-radius: 12px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
      width: 100%;
      padding: 35px 30px;
      p {
        color: #666;
        font-size: 24px;
      }
      .score_shabox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        font-size: 28;
        .thrid_per {
          color: #333333;
        }
        .thrid_tg {
          color: #2b8df0;
        }
      }
      * {
        margin: 0;
        padding: 0;
      }
    }

    .check_grade {
      .ct1 {
        margin-right: 10px;
      }
      .ct2 {
        margin-left: 10px;
      }
      h3 {
        margin-bottom: 10px !important;
        color: #000 !important;
      }
      .van-cell::after {
        border: unset;
      }
      .van-field {
        border-radius: 12px;
      }
    }
  }
  .footer-statistics {
    width: 100%;
    background: #fff;
    padding: 34px 30px 28px;
  }
  .footer-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    .kh_Word {
      font-size: 32px;
    }
    .kh_Socre {
      color: #2b8df0;
      font-size: 40px;
    }
  }
  .footer-button {
    width: 100%;
    padding: 25px 50px 0;
    margin-top: 20px;
  }
}

input[disabled],
input:disabled {
  background: #ddd !important;
}
</style>
